.hidden {
  display: none;
}

.sticky-footer {
  background-color: #e5e5e5;
  margin-top: 100px;
}

.footer-basic {
  display: flex;
  flex-direction: row;
  padding: 40px 0;
  background-color: #e5e5e5;
  color: #4b4c4d;
}

.info,
.social,
.list-inline {
  cursor: pointer;
  flex-basis: 33.33%; /* Equal space for each section */
  text-align: center;
  user-select: none;
}

.footer-basic ul {
  padding: 0;
  list-style: none;
  text-align: center;
  font-size: 18px;
  line-height: 1.6;
  margin-bottom: 0;
}

.footer-basic li {
  padding: 0 10px;
}

.footer-basic ul a {
  color: inherit;
  text-decoration: none;
  opacity: 0.8;
}

.footer-basic ul a:hover {
  opacity: 1;
}

.footer-basic .social {
  text-align: center;
  padding-bottom: 25px;
}

.footer-basic .social > a {
  font-size: 24px;
  width: 40px;
  height: 40px;
  line-height: 40px;
  display: inline-block;
  text-align: center;
  border-radius: 50%;
  border: 1px solid #ccc;
  margin: 0 8px;
  color: inherit;
  opacity: 0.75;
}

.footer-basic .social > a:hover {
  opacity: 0.9;
}

.footer-basic .copyright {
  margin-top: 15px;
  text-align: center;
  font-size: 13px;
  color: #aaa;
  margin-bottom: 0;
}

@media only screen and (max-width: 518px) {
  .footer-basic {
    flex-direction: column;
  }

  .info,
  .social,
  .list-inline {
    margin-top: 20px;
  }
}
