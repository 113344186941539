* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.container {
  min-width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  flex-direction: row;
  padding: 10px;
}

.hidden-cont {
  display: none;
}

.center {
  text-align: center;
}

.product__card {
  width: 8em; /* Adjust the width of each card as needed */
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  text-align: center;
}

.product__img {
  object-fit: contain;
  width: 100%;
  height: auto;
  min-height: 4em;
  max-height: 5em;
  border-radius: 5px;
  z-index: 99999;
}

.product_desc__img {
  object-fit: contain;
  max-width: 100%;
  height: 60vh;
}

.product__name {
  font-weight: 400;
  margin: 10px;
  white-space: nowrap; /* Prevent the text from wrapping */
  overflow: hidden; /* Hide any content that overflows the container */
  text-overflow: ellipsis; /* Show ellipsis (...) for overflowed text */
}

.btn-p {
  background-color: black;
  color: #fff;
  border: 0;
  padding: 8px;
  border-radius: 2px;
  transition: all 1s;
}

.btn-p:hover {
  background-color: rgb(94, 85, 85);
}

.description__container {
  background-color: #e5e5e5;
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  padding: 2em;
}

.description__content {
  display: flex;
  align-items: center;
  padding-top: 3em;
}

.description {
  padding: 5em;
  width: 40%;
}

.buttons2 {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.buttons2 button {
  color: #fff;
  height: 3em;
  background-color: #000;
  border: 0;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 600;
  margin-left: 10px;
  margin-right: 10px;
}

.btn-danger {
  background-color: rgb(221, 17, 17);
  padding: 8px 12px;
  border: 0;
  color: #fff;
  margin-right: 10px;
}

.btn-danger:hover {
  background-color: rgb(124, 24, 24);
}

.btn-success {
  background-color: rgb(7, 143, 7);
  padding: 8px 12px;
  border: 0;
  color: #fff;
}

.add {
  margin-left: 20px;
}

.prd__name {
  font-size: 32px;
  font-weight: 600;
}

.btn-back {
  padding: 5px;
  margin: 10px;
}

@media only screen and (max-width: 1050px) {
  .description {
    width: 100%;
  }
}
@media only screen and (max-width: 768px) {
  .description__content {
    flex-direction: column;
    max-width: 100%;
    padding: 0;
  }

  .product_desc__img {
    all: unset;
    width: 100%;
    margin-bottom: 3em;
  }

  .prd__name {
    margin-bottom: 1em;
  }

  .buttons2 button {
    padding: 2px 8px;
  }

  .description__container {
    height: 100%;
  }

  .product_img__cont {
    padding: 0;
  }

  .description {
    padding: 0;
  }
}

/* search input  */

.search__cont {
  background-color: #000;
  width: 90%;
  border-radius: 10px;
  height: 2.5rem;
  padding: 0, 15px;
  box-shadow: 0px 8px color #ddd;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px;
}

.search__cont input {
  background-color: transparent;
  border: 0;
  height: 100%;
  font-size: 1.25rem;
  width: 100%;
  margin-left: 1rem;
  color: #fff;
}

.search__cont input:focus {
  outline: none;
}

.search {
  /* z-index: 99; */
  color: #fff;
  margin-right: 20px;
}

.search__result {
  position: absolute;
  top: 10em;
  width: 80%;
  background-color: #ccc;
  padding: 10px;
  max-height: 70vh;
  overflow-y: scroll;
  display: flex;
  flex-wrap: wrap;
}

.search__result .product__card {
  border-color: #fff;
  margin-top: 10px;
  margin: 10px;
}

.instructions {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgb(7, 143, 7);
  max-width: 300px;
  padding: 10px;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.instructions button {
  color: #fff;
  height: 2.4em;
  background-color: #000;
  border: 0;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 600;
  padding: 0px 20px;
  margin-top: 10px;
}

.remove {
  display: none;
}

@media only screen and (max-width: 592px) {
  .product__card {
    width: 10em;
  }
}

@media only screen and (max-width: 540px) {
  .container {
    gap: 40px;
  }
}

@media only screen and (max-width: 380px) {
  .product__card {
    width: 12em;
  }

  .container {
    justify-content: center;
  }
}
