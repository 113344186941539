.home {
  width: 100%;
  height: 100vh;
}

.pages {
  padding-left: 1em;
  padding-right: 1em;
}

.pages h2 {
  font-size: 44px;
  padding-left: 10px;
  padding-bottom: 0.5em;
}

.about__text p {
  line-height: 1.5rem;
}

.highlight {
  background-color: rgba(202, 112, 112, 0.164);
  color: #000;
  padding: 3px;
}

.sub__section {
  padding: 2em;
}

.sub__section::before {
  content: "";
  display: block;
  width: 5px;
  height: 50px;
  background-color: red;
  margin-left: -10px; /* Adjust spacing as needed */
}

.summary {
  background-color: rgba(202, 112, 112, 0.164);
  color: #000;
  padding: 8px;
}

.contact {
  margin-top: 2em;
}

.contact_box {
  padding: 20px;
  width: 50%;
  margin-left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  border-radius: 10px;
  box-shadow: 0px 0px 4px black;
}

.contact__box2 {
  padding: 20px;
  width: 20%;
  margin-left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  border-radius: 10px;
  box-shadow: 0px 0px 4px black;
  margin-top: 40px;
}

.contact__box2 .contact__card {
  margin: 0;
  margin-left: 50%;
  transform: translateX(-50%);
}

.contact_box h3 {
  font-size: 20px;
}

.contact__card {
  margin-left: 20px;
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.fa {
  margin-bottom: 20px;
  text-align: center;
  color: rgb(78, 196, 32);
}

@media only screen and (max-width: 1000px) {
  .contact_box {
    flex-direction: column;
    padding: 10px;
  }

  .contact__card {
    margin-top: 40px;
  }
}

@media only screen and (max-width: 1000px) {
  .contact_box {
    width: 100%;
  }
}
