* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.heading__text {
  text-align: center;
  padding: 20px 0px;
}

.product__form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid #ccc;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 10px;
  border-radius: 6px;
  padding-top: 2rem;
}

.product__form input[type="text"] {
  width: 100% !important;
  height: 1.8rem;
  margin-top: 5px;
}

.form__style {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  margin-top: 20px;
}

.form__style input {
  margin: 5px;
}

.btn-success {
  background-color: rgb(7, 143, 7);
  padding: 8px 12px;
  border: 0;
  color: #fff;
}
